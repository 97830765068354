<!--index:Hi, 好友动态 2024/07/03 22:51:52 YuAng-->

<template>
  <div class="friend-news-index" v-infinite-scroll="getUserDynamicList" :infinite-scroll-immediate=false
    :infinite-scroll-distance=30 :infinite-scroll-disabled="!loadMore">
    <friend-news-item v-for="(item, index) in list" :key="'fridend' + index" :item="item"
      :is-ta=true></friend-news-item>
  </div>
</template>
<script>
import FriendNewsItem from './components/FriendNewsItem.vue'
import { interactionApi, userApi, articleApi } from '@/utils/api'
export default {
  name: 'index',
  components: { FriendNewsItem },
  props: {
    item: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      currentPage: 1,
      list: [],
      loadMore: true,
      attentionUserIds: '',
      isloading: false,
      isTa: this.$route.query.id !== undefined && this.$route.query.id !== ''
    }
  },
  created () {
    // 请求接口
  },
  mounted () {
    if (!this.isTa) {
      this.getMyAttentions()
    } else {
      this.getUserDynamicList()
    }
  },
  methods: {
    getMyAttentions: function (action) {
      interactionApi.getMyAttentions().then(resp => {
        if (resp.httpStatus === 200 && resp.data) {
          console.log('getMyAttentions', resp)
          // 获取好友的动态
          this.attentionUserIds = resp.data.map(item => item.id).join(',')
          this.getUserDynamicList()
        }
      })
    },
    getUserDynamicList: function () {
      if (!this.isTa && (!this.attentionUserIds || this.attentionUserIds.length === 0)) {
        return
      }
      if (this.isloading) {
        return
      }
      this.isloading = true
      const param = {
        currentPage: this.currentPage,
        pageSize: 10
      }
      if (!this.isTa) {
        param.userIds = this.attentionUserIds
      } else {
        param.userIds = this.$route.query.id
        param.userDynamicType = 'PUBLISH_POST'
      }
      userApi.getUserDynamicList(param).then(resp => {
        this.isloading = false
        if (resp.httpStatus === 200 && resp.data) {
          console.log('getUserDynamicList', resp)
          this.loadMore = resp.currentPage * resp.pageSize < resp.totalSize
          if (resp.data && resp.data.length > 0) {
            this.list = this.list.concat(resp.data)
            this.getArticleInteractions()
          }
          this.currentPage++
        }
      }).catch(err => {
        console.log('err', err)
        this.isloading = false
      })
    },
    getArticleInteractions: function () {
      const articles = this.list.filter(item => item.article !== null)
      if (!articles || articles.length === 0) {
        return
      }
      const idSet = new Set(articles.map(item => item.article.id))
      const articleIds = Array.from(idSet).join(',')
      articleApi.getArticleInteractions({
        articleIds: articleIds
      }).then(resp => {
        if (resp.httpStatus === 200) {
          // 为 articles 追加 互动数据
          const interactionMap = new Map(resp.data.map(interaction => [interaction.articleId, interaction]))
          this.list = this.list.map(item => {
            const interactionData = interactionMap.get(item.article.id)
            if (interactionData) {
              // 如果找到互动数据，则追加 likeNum 和 commentNum
              item.article = {
                ...item.article,
                like: interactionData.like,
                likeInteractionRecordId: interactionData.likeInteractionRecordId,
                trample: interactionData.trample,
                trampleInteractionRecordId: interactionData.trampleInteractionRecordId,
                likeNum: interactionData.likeNum,
                commentNum: interactionData.commentNum
              }
            }
            return item
          })
          console.log('friends', this.list)
        }
      })
    }
  }
}
</script>

<style scoped>
.friend-news-index {
  min-height: 700px;
  overflow-y: auto;
}
</style>
