<!--Comment:评论组件 2024/07/27 15:06:41 huangmx-->

<template>
  <div class="comment">
    <div class="text-gray-800 leading-5 font-bold break-all break-words">{{ item ? item.content : '' }}</div>
    <div class="item-content-imgs" v-if="item && item.pictureList && item.pictureList.length > 0">
      <div class="img-item" v-for="img, index in item.pictureList" :key="'img_' + index"
        :style="{ backgroundImage: 'url(' + img + ')' }"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Comment',
  props: {
    item: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      tip: 'Hi, 新的组件'
    }
  },
  created () {
    // 请求接口
  },
  mounted () {
    // 更新Dom
  },
  methods: {
    xxxAction: function (action) {
      console.log(action)
    }
  }
}
</script>

<style scoped>
.comment {
  border-color: rgb(216, 216, 216);
  border-bottom: 1px rgb(216, 216, 216);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  line-height: 171px;
  margin: 15px 0;
  padding-bottom: 10px;
  color: rgb(82, 82, 82);
}

.break-all {
  word-break: break-all;
}

.break-words {
  word-wrap: break-word;
}

.text-gray-800 {
  color: rgb(50, 50, 50);
}

.leading-5 {
  line-height: 20px;
}

.font-bold {
  font-weight: 700;
}

.comment .item-content-imgs {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.comment .item-content-imgs .img-item {
  width: 130px;
  height: 130px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 14px;
  overflow: hidden;
}
</style>
