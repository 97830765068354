<!--FriendNewsItem:好友动态 列表项 item 2024/07/27 17:28:51 huangmx-->

<template>
  <div class="friend-news-item">
    <head-common :item="item.userPublicVO" :show-footer="false">
      <p class="post-msg">
        <span>{{ getTypeDesc() }}</span>
        <span>{{ new Date(item.gmtCreate).getTime() | formatDate }}</span>
        <el-tag effect="dark" :type="item.userDynamicType === 'PUBLISH_ARTICLE_COMMENT' ? 'success' : ''" size="small">
          {{ item.userDynamicType === 'PUBLISH_ARTICLE_COMMENT' ? $t('comment') : $t('post') }}
        </el-tag>
      </p>
      <div class="inter-hort" v-if="isTa" :class="{ 'hort-active': liked }" @click.stop="interact">
        <img class="inter-img" src="../../../assets/images/hot.svg" />
        <span>{{ liked ? likeTimes : $t('fire') }}</span>
      </div>
    </head-common>
    <div>
      <!--评论的内容-->
      <comment class="my-comment" v-if="item.userDynamicType === 'PUBLISH_ARTICLE_COMMENT'" :item="item.articleComment">
      </comment>
      <!--评论的咨询/帖子-->
      <feed-list-common v-if="item.article.type === 'POST'" class="article-item" :item="item.article"
        :show-header="false"></feed-list-common>
      <feed-list-rich v-else class="article-item" :item="item.article" :show-header="false"></feed-list-rich>
    </div>
  </div>
</template>
<script>
import Comment from '@/views/components/Comment'
import HeadCommon from '@/views/components/HeadCommon'
import FeedListCommon from '@/views/components/FeedListCommon'
import FeedListRich from '@/views/components/FeedListRich'
import { interactionApi, trackApi } from '@/utils/api'

export default {
  name: 'FriendNewsItem',
  components: { Comment, HeadCommon, FeedListCommon, FeedListRich },
  props: {
    item: {
      type: Object,
      default: function () {
        return {}
      }
    },
    isTa: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      liked: false,
      likeTimes: 0,
      likeInteractionRecordId: ''
    }
  },
  watch: {
    item: {
      handler (newVal, oldVal) {
        if (newVal.userDynamicType === 'PUBLISH_ARTICLE_COMMENT') {
          // 发表评论
          if (newVal.articleComment && newVal.articleComment.interaction) {
            this.likeTimes = newVal.articleComment.interaction.likeNum || 0
            this.liked = newVal.articleComment.interaction.like || false
            this.likeInteractionRecordId = newVal.articleComment.interaction.likeInteractionRecordId
          }
        } else {
          if (newVal.article) {
            this.likeTimes = newVal.article.likeNum || 0
            this.liked = newVal.article.like || false
            this.likeInteractionRecordId = newVal.article.likeInteractionRecordId
          }
        }

        console.log('watch', newVal)
      },
      deep: true,
      immediate: true
    }
  },
  created () {
  },
  mounted () {
    // 更新Dom
  },
  methods: {
    getTypeDesc: function () {
      if (this.item.userDynamicType === 'PUBLISH_POST') {
        return this.$t('publishPost')
      } else if (this.item.userDynamicType === 'PUBLISH_ARTICLE_COMMENT') {
        return this.$t('publishComment')
      } else if (this.item.userDynamicType === 'LIKE_ARTICLE') {
        return this.$t('firePost')
      }
    },
    interact: function () {
      if (!this.checkLogin()) {
        return
      }
      this.liked = !this.liked
      if (this.liked) {
        this.likeTimes++
        // 点赞
        // 如果是文章，还需要将浇水给取消
        if (this.item.userDynamicType === 'PUBLISH_ARTICLE_COMMENT') {
          this.addInteraction(this.item.articleComment.id, 'COMMENT', 'LIKE')
          // 点赞评论 埋点
          trackApi.articleTrack('LIKE_COMMENT', null, null, this.item.articleComment.id)
        } else {
          this.addInteraction(this.item.article.id, 'ARTICLE', 'LIKE')
          // 点赞文章 埋点
          trackApi.articleTrack('LIKE_ARTICLE', this.item.article.id, null, null)
          // 如果已经浇过水了，还需要将浇水给删掉
          if (this.item.article.trample) {
            this.delInteraction(this.item.article.trampleInteractionRecordId)
          }
        }
      } else {
        // 取消点赞
        this.likeTimes--
        this.delInteraction(this.likeInteractionRecordId)
      }
    },
    addInteraction: function (targetId, targetType, interactionType) {
      const params = {
        targetId: targetId,
        targetType: targetType,
        interactionType: interactionType
      }
      interactionApi.createInteraction(params).then(resp => {
        console.log('添加互动成功', resp)
        if (resp.httpStatus === 200) {
          this.likeInteractionRecordId = resp.data
        }
      })
    },
    delInteraction: function (interactionId) {
      if (!interactionId) {
        return
      }
      interactionApi.delInteraction(interactionId).then(resp => {
        console.log('删除互动', resp)
      })
    },
    checkLogin: function () {
      if (!this.$store.state.user || this.$store.state.user.name === '未登录') {
        this.$store.state.loginVisible = true
        return false
      }
      return true
    }
  }
}
</script>

<style scoped>
.friend-news-item {
  background-color: white;
  border-radius: 14px;
  padding: 10px 20px;
  margin-bottom: 20px;
}

.my-comment {
  border-bottom: 1px dashed #eee;
}

.article-item {
  padding: 0;
}

.post-msg {
  margin-left: 20px;
  font-weight: bold;
}

.post-msg span {
  margin-right: 20px;
}

.inter-hort {
  width: 90px;
  height: 40px;
  border-radius: 20px;
  padding: 15px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  margin-right: 14px;
  box-shadow: 0 0 10px 4px rgba(19, 128, 240, .08);
  color: rgb(19, 128, 240);
  overflow: hidden;
  position: absolute;
  left: 667px;
}

.hort-active {
  margin-right: 0;
  color: white;
  background-color: #e81919;
}

.inter-img {
  width: 18px;
  height: 18px;
  translate: -100px;
  filter: drop-shadow(100px 0 0 rgb(19, 128, 240));
}

.hort-active .inter-img {
  translate: -100px;
  filter: drop-shadow(100px 0 0 white);
}
</style>
