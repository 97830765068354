<!--FeedListRich:Hi, 文章列表项 2024/07/14 10:51:58 YuAng-->

<template>
  <div @click="handleLinkClick()" class="add-animate">
    <div class="header" v-if="showHeader">
      <head-common :item="user">
        <slot name="header"></slot>
      </head-common>
    </div>
    <div class="feed-list-rich">
      <div class="left">
        <img class="animate-img" :src="item.covers" :alt="$t('yingDi')" />
      </div>
      <div class="right">
        <div>
          <div class="title">{{ item.title }}</div>
          <div class="desc">{{ item.summary }}</div>
        </div>
        <div>
          <div class="tags">
            <tag v-for="tag in computedTags" :key="'tag' + tag.id" :item="tag"></tag>
          </div>
          <div class="info">
            <span>{{ item.likeNum || 0 }} {{ $t('fire') }}</span>
            <span class="dot"></span>
            <span>{{ item.commentNum || 0 }} {{ $t('reply') }}</span>
            <span class="dot"></span>
            <span>{{ new Date(item.gmtCreate).getTime() | formatTimeAgo }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Tag from './Tag.vue'
import HeadCommon from './HeadCommon.vue'
export default {
  components: { Tag, HeadCommon },
  name: 'FeedListRich',
  props: {
    item: {
      type: Object,
      default: function () {
        return {
          id: 1,
          time: Date.now(),
          tag: {
            id: 1,
            name: '炉石传说'
          }
        }
      }
    },
    userInfo: {
      type: Object,
      default: function () {
        return null
      }
    },
    showHeader: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  computed: {
    user: function () {
      return this.userInfo || this.item.authorInfo
    },
    computedTags () {
      const { labels, classifications } = this.item
      if (Array.isArray(labels) && labels.length > 0) {
        return labels
      } else if (Array.isArray(classifications)) {
        return classifications
      }
      return []
    }
  },
  created () {
  },
  mounted () {
    // 更新Dom
  },
  methods: {
    handleLinkClick: function () {
      this.$router.push('/post/' + this.item.id)
    }
  }
}
</script>

<style scoped>
.add-animate {
  background-color: white;
  border-radius: 14px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  margin-bottom: 20px;
}

.feed-list-rich {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.add-animate .header {
  min-height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.feed-list-rich .left {
  width: 287px;
  height: 160px;
  border-radius: 8px;
  overflow: hidden;
}

.feed-list-rich .right {
  width: 431px;
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.feed-list-rich .right .title {
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: rgb(82, 82, 82);
  word-break: break-all;
}

.feed-list-rich .right .desc {
  width: 100%;
  height: 43px;
  color: #969696;
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
  word-break: break-all;
}

.feed-list-rich .right .tags {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 8px;
}

.feed-list-rich .right .info {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  color: rgb(185, 185, 185);
}

.feed-list-rich .right .info span {
  margin-right: 8px;
}

.feed-list-rich .right .info .dot {
  width: 3px;
  height: 3px;
  background-color: rgb(185, 185, 185);
  border-radius: 50%;
}
</style>
